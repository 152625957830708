import React from 'react'
import { Box, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import styled from '@emotion/styled';

const BoxStyled = styled(Box)`
  padding: 32px 0;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: black;
  color: white;
`;

function Footer() {
    return (
        <BoxStyled>
            <IconButton color='secondary' target='_blank' href='https://www.facebook.com/profile.php?id=61561148197318'><FacebookIcon /></IconButton>
            <IconButton color='secondary' target='_blank' href='https://www.instagram.com/fitrahrenaissance'><InstagramIcon /></IconButton>
            <IconButton color='secondary' target='_blank' href='https://wa.me/+923292324652'><WhatsAppIcon /></IconButton>
        </BoxStyled>
    )
}

export default Footer