import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import TopBar from './components/TopBar';
import LandingPage from './routes/LandingPage';
import JoinPage from './routes/JoinPage';
import styled from '@emotion/styled';
import bgimage from './images/fitrah.png'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AboutPage from './routes/AboutPage';
import Footer from './components/Footer';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000'
    },
    secondary: {
      main: '#ffffff'
    },
  }
});

const Root = styled.div`
flex-grow: 1;
background-image: url(${bgimage});
background-size: 100% 90%;
background-attachment: fixed;
background-position: center bottom;
background-repeat: no-repeat;
background-color: #666;
@media (max-width: 600px) {
  background-size: 150% 95%;
  background-position: left bottom;
}
`;


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <Root>
          <TopBar />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/join" element={<JoinPage />} />
            <Route path="/about" element={<AboutPage />} />
          </Routes>
          <Footer />
        </Root>
      </Router>
    </ThemeProvider>
  );
}

export default App;