import React from 'react';
import { CardMedia } from '@mui/material';
import vid from '../images/about.webm'
import Hero from '../components/Hero';

function AboutPage() {
  return (
    <>
      <Hero width={"500px"}>
        <CardMedia sx={{ height: "auto" }} component='video' src={vid} autoPlay />
      </Hero>
    </>
  );
}

export default AboutPage;
