import React, { useRef } from 'react';
import { Typography, Grid, Box, Button } from '@mui/material';
import styled from '@emotion/styled';
import img from '../images//img.jpg'
import Form from '../components/Form';
import Hero from '../components/Hero';

const Section = styled(Box)`
  padding: 80px 0;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: white;
  @media (max-width: 600px) {
    padding: 60px 0;
  }
`;

const Column = styled(Grid)`
  align-items: center;
  text-align: center;
`;

const Img = styled(Grid)`
  background-image: url(${img});
  background-size: fit;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 300px;
  @media (max-width: 900px) {
    margin-top: 30px;
  }
`;

const JoinPage = () => {
  const formRef = useRef(null)

  const executeScroll = () => formRef.current.scrollIntoView({ behavior: 'smooth' })

  return (
    <>
      <Hero width={"60%"}>
        <Typography sx={{ typography: { md: 'h5', xs: 'subtitle1' } }}>Join the struggle for Islamic system</Typography>
        <Typography sx={{ typography: { md: 'body1', xs: 'body2' } }}>Join the cause for the domination of Islam as a social, economical and political system. We are dedicated promoting Islamic social and political system and for solving the problems faced in its path.</Typography>
        <Button onClick={() => executeScroll()} type="submit" variant="contained" color="secondary">Join Now</Button>
      </Hero>
      <Section ref={formRef}>
        <Grid container>
          <Column item xs={12} md={6}>
            <Form />
          </Column>
          <Img item xs={12} md={6} />
        </Grid>
      </Section>
    </>
  );
};

export default JoinPage;