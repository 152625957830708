import React, { useState } from 'react';
import { TextField, Button, MenuItem, Grid, Container, Typography } from '@mui/material';

const genderOptions = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' }
];

const qualifications = [
  { value: 'pre-metric', label: 'Pre-Metric' },
  { value: 'metric', label: 'Metric / O level' },
  { value: 'intermediate', label: 'Intermediate / A level' },
  { value: 'bachelor', label: 'Bachelor' },
  { value: 'master', label: 'Master' },
  { value: 'phd', label: 'PhD' },
  { value: 'other', label: 'Other' }
];

const Form = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    dob: '',
    gender: '',
    city: '',
    qualification: '',
    skillset: ''
  });
  const [isSubmitted, setSubmitted] = useState(false)

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true)
    const data = new FormData();
    data.append('firstName', formData.firstName);
    data.append('lastName', formData.lastName);
    data.append('email', formData.email);
    data.append('phone', formData.phone);
    data.append('dob', formData.dob);
    data.append('gender', formData.gender);
    data.append('city', formData.city);
    data.append('qualification', formData.qualification);
    data.append('skillset', formData.skillset);

    const Sheet_Url = "https://script.google.com/macros/s/AKfycbyISol7croBXnI5xNgBuLyZIg6rrhQrjiadQEfZgAWxadxM0B7fsTjPA8icuW2-HVLZ/exec"
    try {
      await fetch(Sheet_Url, {
        method: 'POST',
        body: data,
        muteHttpExceptions: true,
      });

      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        dob: '',
        gender: '',
        city: '',
        qualification: '',
        skillset: ''
      });
    } catch (error) {
      setSubmitted(false)
    }
  };

  if (isSubmitted) return (
    <Container maxWidth="xs">
      <button onClick={() => { setSubmitted(false) }}>
        Go Back
      </button>
      <div style={{ margin: "10px 0" }}>
        <Typography sx={{ lineHeight: "1rem" }} variant='overline'>Form is Submitted,<br /> optionally Join any of the group</Typography>
        <Typography>
          <Button variant="contained" color="secondary" target='_blank' href='https://chat.whatsapp.com/Id4n25TcQbHI6G0TDHgDaf'>
            Group 1
          </Button>
          <Button variant="contained" color="secondary" target='_blank' href='https://chat.whatsapp.com/DTMrXEK5r9e5y7IWilHVQl'>
            Group 2
          </Button>
        </Typography>
      </div>
    </Container>
  )

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" component="h1" gutterBottom>
        Submit Yourself
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="firstName"
              label="First Name"
              fullWidth
              variant="outlined"
              value={formData.firstName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="lastName"
              label="Last Name"
              fullWidth
              variant="outlined"
              value={formData.lastName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="email"
              label="Email"
              fullWidth
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="phone"
              label="Phone #"
              fullWidth
              variant="outlined"
              value={formData.phone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="dob"
              label="Date of Birth"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
              value={formData.dob}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="gender"
              label="Gender"
              sx={{ textAlign: "left" }}
              select
              fullWidth
              variant="outlined"
              value={formData.gender}
              onChange={handleChange}
            >
              {genderOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="city"
              label="City"
              fullWidth
              variant="outlined"
              value={formData.city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="qualification"
              label="Qualification"
              sx={{ textAlign: "left" }}
              select
              fullWidth
              variant="outlined"
              value={formData.qualification}
              onChange={handleChange}
            >
              {qualifications.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} rows={2}>
            <TextField
              required
              name="skillset"
              label="Skillset"
              fullWidth
              variant="outlined"
              value={formData.skillset}
              onChange={handleChange}
              helperText="Specify how can you help the cause"
              multiline
              rows={2}
            >
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Form;
